<template>
  <div class="app-container">
    <el-row :gutter="16">
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="engineer">
            <div>服务商信息</div>
            <div>
              <div class="set">
                <p>名称：{{engineerData.projectName}}</p>
                <p>
                  <el-tag @click="EngineerEdit" size="small" class="getMoneys">编辑</el-tag>
                </p>
              </div>
              <p>联系电话：{{engineerData.phone}}</p>
              <p>创建时间：{{engineerData.createTime}}</p>
              <p>地址：{{engineerData.address}}</p>
              <p>状态：{{engineerData.state}}</p>
              <p>备注：{{engineerData.remark}}</p>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="8">
        <el-card shadow="hover">
          <div class="engineer" style="padding-bottom:140px">
            <div>账户余额</div>
            <div>
              <div class="set">
                <p>
                  可用余额：
                  <span style="color:red;margin:0">{{account.balanceAvailableTotal}}</span> (元)
                </p>
                <p>
                  <el-tag
                    @click="getMoneyInformation"
                    size="small"
                    class="getMoneys"
                    type="success"
                  >明细</el-tag>
                  <el-tag @click="getMoney" size="small" class="getMoneys">提现</el-tag>
                </p>
              </div>
              <p>
                冻结余额：
                <span style="color:red">{{account.balanceFrozenTotal}}</span> (元)
              </p>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 编辑弹窗开始  -->
    <el-dialog title="编辑服务商" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="名称：" :label-width="formLabelWidth">
          <el-input v-model="form.projectName" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="地址：" :label-width="formLabelWidth">
          <el-input v-model="form.address" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话：" :label-width="formLabelWidth">
          <el-input v-model="form.phone" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="详情：" :label-width="formLabelWidth">
          <el-input v-model="form.remark" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="创建时间" :label-width="formLabelWidth">
          <el-input v-model="form.createTime" auto-complete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="状态：" :label-width="formLabelWidth">
          <el-input v-model="form.state" auto-complete="off" :disabled="true"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="DetermineEdit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑弹窗结束 -->
    <!-- 提现开始 -->
    <el-dialog title="提现申请" :visible.sync="dialogFormMoney" width="30%">
      <el-tooltip placement="bottom-start" effect="light">
        <div slot="content" style="font-weight:600;font-size:14px">
          点击提现，填入自己的提现金额，同时在说明里写上发票寄送方式和单号，
          <br />比如 顺丰快递：X02933989，点击提交后处于待审核状态，等财务
          <br />人员收到发票后会审核通过自动、完成提现，发票金额需不小于
          <br />提现金额，否则会被财务拒掉。
          <br />第二行信息
          <br />
          <br />单 位：
          <br />税 号：
          <br />开 户 行：
          <br />银行账号：
          <br />电 话：
          <br />地 址：
          <br />发票类目：
          <br />发票种类：
          <br />
          <br />收件人联系人：
          <br />电话：
          <br />收件地址：
        </div>
        <span class="titleInformation">提交说明</span>
      </el-tooltip>
      <div style="width:90%;maring:0 auto">
        <el-form :model="formMoney">
          <el-form-item label="申请类型" label-width="170px">
            <el-radio-group v-model="formMoney.applyType" style="margin-top:12px">
              <el-radio :label="1">对公</el-radio>
              <el-radio :label="2">对私</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 对公 -->
          <el-form-item
            label="收款单位银行账号"
            label-width="175px"
            v-if="formMoney.applyType==1"
            prop="bankCard"
            :rules="[{ required: true, message: '收款单位银行账号不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="formMoney.bankCard" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="收款单位开户行"
            label-width="175px"
            v-if="formMoney.applyType==1"
            prop="bankName"
            :rules="[{ required: true, message: '收款单位开户行不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="formMoney.bankName" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="收款单位名称"
            label-width="175px"
            v-if="formMoney.applyType==1"
            prop="accountName"
            :rules="[{ required: true, message: '收款单位名称不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="formMoney.accountName" auto-complete="off"></el-input>
          </el-form-item>
          <!-- 对私 -->
          <el-form-item
            label="收款银行账号"
            label-width="175px"
            v-if="formMoney.applyType==2"
            prop="bankCard"
            :rules="[{ required: true, message: '收款银行账号不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="formMoney.bankCard" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="收款银行开户行"
            label-width="175px"
            v-if="formMoney.applyType==2"
            prop="bankName"
            :rules="[{ required: true, message: '收款银行开户行不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="formMoney.bankName" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="收款人姓名"
            label-width="175px"
            v-if="formMoney.applyType==2"
            prop="accountName"
            :rules="[{ required: true, message: '收款人姓名不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="formMoney.accountName" auto-complete="off"></el-input>
          </el-form-item>

          <el-form-item
            label="金额(元)"
            label-width="175px"
            prop="money"
            :rules="[{ required: true, message: '金额不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="formMoney.money" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="备注"
            label-width="175px"
            prop="remark"
            :rules="[{ required: true, message: '备注不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="formMoney.remark" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormMoney = false" style="margin-right:20px">取 消</el-button>
        <el-popover placement="top" width="250" v-model="visible">
          <p style="color:#EBA549;font-weight:600">此操作将影响你的资金安全, 请再次仔细确定你填写的信息是否正确无误, 此操作成功后不可恢复？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false" style="margin-right:30px">取消</el-button>
            <el-tag type="warning" size="mini" @click="confirmWithdrawal">确定</el-tag>
          </div>
          <el-button type="primary" slot="reference">确 定</el-button>
        </el-popover>
      </div>
    </el-dialog>
    <!-- 提现结束 -->
    <!-- 提现明细 -->
    <el-dialog title="提现明细" :visible.sync="dialogTableVisible" width="50%">
      <el-table :data="moneyDetailed" max-height="460">
        <el-table-column property="id" label="申请编号"></el-table-column>
        <el-table-column property="bankCard" label="收款单位银行账号"></el-table-column>
        <el-table-column property="bankName" label="收款单位开户银行"></el-table-column>
        <el-table-column property="accountName" label="收款单位名称"></el-table-column>
        <el-table-column property="applyType" label="申请类型"></el-table-column>
        <el-table-column property="money" label="申请金额"></el-table-column>
        <el-table-column property="applyTime" label="申请时间"></el-table-column>
        <el-table-column property="userId" label="申请的员工编号"></el-table-column>
        <el-table-column property="applyState" label="审核状态"></el-table-column>
        <el-table-column property="financeState" label="财务状态"></el-table-column>
        <el-table-column property="remark" label="备注"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      engineerData: {}, //服务商信息数据
      account: {}, //账户余额
      FormVisible: false, //创建员工弹窗
      formLabelWidth: "80px", //弹框输入框宽度
      addEngineer: {
        //创建工程商
        projectName: "",
        phone: "",
        remark: "",
        address: ""
      },
      dialogFormVisible: false, //弹框值
      form: {}, //编辑弹窗表单

      dialogFormMoney: false,
      formMoney: {
        applyType: 1,
        bankCar: "",
        bankName: "",
        accountName: "",
        money: "",
        remark: ""
      },
      visible: false,
      operation: true,
      getMoneyValue: true,

      // 提现明细
      dialogTableVisible: false,
      moneyDetailed: [] //获取的所有明细数据
    };
  },
  created() {
    this.getEngineerData();
  },
  methods: {
    //获取数据
    getEngineerData() {
      this.$axios.post("/projectContractor/queryByUser").then(res => {
        if (res.data.state === 1) {
          this.engineerData = res.data.projectContractor;
          this.engineerData.createTime = this.getTiem(
            this.engineerData.createTime
          );
          if (this.engineerData.state === 1) {
            this.engineerData.state = "可用";
          } else if (this.engineerData.state === 0) {
            this.engineerData.state = "不可用";
          } else if (this.engineerData.state === -1) {
            this.engineerData.state = "禁用";
          }
        }
      });
      const projectId = JSON.parse(sessionStorage.getItem("user"))
        .projectContractorId;
      this.$axios
        .get("/projectContractor/getProjectAccount", { params: { projectId } })
        .then(res => {
          if (res.data.state == 1) {
            this.account = res.data.account;
          }
        });
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          +this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },

    //编辑工程商
    EngineerEdit() {
      this.dialogFormVisible = true; //弹窗显示控制
      this.form = { ...this.engineerData };
    },
    //确认编辑
    DetermineEdit() {
      this.$axios
        .get("projectContractor/edit", {
          params: {
            projectId: this.form.id,
            projectName: this.form.projectName.trim(),
            phone: this.form.phone.trim(),
            remark: this.form.remark.trim(),
            address: this.form.address.trim()
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.getEngineerData();
            this.DeleteSuccess();
            this.dialogFormVisible = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    // 提现
    getMoney() {
      if (this.operation) {
        this.dialogFormMoney = true;
      } else {
        this.$message({
          showClose: true,
          message: "操作过于频繁，请稍后尝试",
          type: "warning"
        });
      }
    },
    // 确认提现
    confirmWithdrawal() {
      this.operation = false;
      if (this.getMoneyValue) {
        this.getMoneyValue = false;
        const { id } = this.engineerData;
        const {
          applyType,
          bankCard,
          bankName,
          accountName,
          money,
          remark
        } = this.formMoney;
        if (
          applyType !== "" &&
          bankCard !== "" &&
          bankName !== "" &&
          accountName !== "" &&
          money !== "" &&
          remark !== ""
        ) {
          let paramsData = {
            applyType,
            bankCard,
            bankName,
            accountName,
            money,
            remark,
            projectId: id
          };
          this.$axios
            .get("/projectContractor/applyTcash", { params: paramsData })
            .then(res => {
              if (res.data.state == 1) {
                this.getEngineerData();
                this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: "success"
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: "error"
                });
              }
              this.dialogFormMoney = false;
            });
          this.visible = false;
        } else {
          this.$message({
            showClose: true,
            message: "请填写完整你信息",
            type: "error"
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "操作过于频繁，请5秒后尝试",
          type: "warning"
        });
      }
      setTimeout(() => {
        this.operation = true;
        this.getMoneyValue = true;
      }, 5000);
    },
    // 提现明细
    getMoneyInformation() {
      this.$axios
        .get("/projectContractor/queryTcash", {
          params: { projectId: this.engineerData.id }
        })
        .then(res => {
          if (res.data.state == 1) {
            this.moneyDetailed = res.data.projectProfitApplyPage.map(item => {
              item.applyType = item.applyType == 1 ? "对公" : "对私";
              item.applyTime = this.getTiem(item.applyTime);
              item.financeState =
                item.financeState == 0
                  ? "待处理"
                  : item.financeState == 1
                  ? "已处理"
                  : "";
              item.applyState =
                item.applyState == 0
                  ? "待审核"
                  : item.applyState == 1
                  ? "审核通过"
                  : item.applyState == 2
                  ? "审核不通过"
                  : "";
              return item;
            });
          }
        });
      this.dialogTableVisible = true;
    },
    //操作成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //操作失败提示
    DeleteFailure(data) {
      this.$message.error(data);
    }
  }
};
</script>

<style lang="scss" scoped>
.engineer {
  width: 100%;
  padding: 20px;
  background-color: #ecf5f9;
}
.engineer > div:nth-child(2) {
  margin-left: 4%;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}
.engineer > div:first-child {
  font-weight: 600;
  font-size: 18px;
}
.engineer > div > p {
  font-size: 14px;
  font-weight: 600;
}
.set {
  display: flex;
  justify-content: space-between;
}
.set > p {
  margin-bottom: 0;
}
.set > p > span {
  margin-right: 10px;
}

.el-dialog__title {
  color: white !important;
  font-weight: 600;
  font-size: 22px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.moneys {
  width: 100%;
  margin: 106px auto;
  text-align: center;
}
.getMoneys {
  cursor: pointer;
}
.titleInformation {
  color: #604094;
  border-bottom: 1px solid #604094;
}
</style>